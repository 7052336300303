import React from 'react';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import createStore from './src/state/createStore';

Bugsnag.start({
  apiKey: 'f3aa44e86a4d6bd26a3dcfb638abc4e2',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const store = createStore();

const wrapWithProvider = ({ element }) => (
  <Provider store={store}>
    <ErrorBoundary>
      {element}
    </ErrorBoundary>
  </Provider>
);

export const wrapRootElement = wrapWithProvider;
